html {
    /* background: rgb(189, 206, 222); */

    /* Mørk grønn bakgrunn, svak skrånende gradient: */
    /* background: linear-gradient(220.55deg, #AFCCCB 0%, #636465 100%); */
    background: #a5bfbd;

    /* sterk fersken: */
    /* background: linear-gradient(220.55deg, #FFC328 0%, #E20000 100%); */

    /* random sterke farger */
    /* background: linear-gradient(220.55deg, #FF5EEF 0%, #456EFF 100%); */


    /* Forsterkede farger fra foto:*/
    /* background: linear-gradient(180deg, rgba(149,189,240,1) 0%, rgba(188,205,234,1) 54%, rgba(209,135,135,1) 100%); */



    /* background: linear-gradient(180deg, rgba(189, 206, 222, 1) 0%, rgba(227, 230, 235, 1) 74%, rgba(215, 191, 187, 1) 100%); */
    font-family: "Baskervville", serif;
    font-size: 1.1rem;
    /* font-family: "Lora", system-ui; */
    /* font-family: "Lato", Arial, Helvetica, sans-serif; */
    line-height: 1.55;
    color: #686262;
}

a {
    color: #121271;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h3 {
    text-transform: uppercase;
    margin-top: 0;
}

@media screen and (min-width: 1401px) {
    .page_container {
        width: 1120px;
        margin: 2rem auto;        
    }

    .item_image {
        min-height: 200px;
    }

    .hero_item {
        min-height: 90vh;
    }

    div.item_container.hero_item > h1 {
        font-size: 4rem;
    }    

    div.item_container.hero_item > h2 {
        font-size: 2rem;
    }    

}

@media screen and (min-width: 600px) and (max-width: 1400px) {
    .page_container {
        width: 80vw;
        margin: 2rem auto;
        /* border: 4px dotted green; */
    }

    div.item_container.hero_item > h1 {
        font-size: 3rem;
        margin-bottom: 0.5rem;
    }    

    div.item_container.hero_item > h2 {
        margin-top: 0;
        font-size: 1.5rem;
    }    

    .item_image {
        min-height: 200px;
    }

    .hero_item {
        min-height: 65vh;
    }

}

@media screen and (max-width: 599px) {
    .page_container {
        width: 100%;
        margin: 0 auto;
        /* border: 4px dotted blue; */
    }
}

.grid_container {
    display: grid;
    gap: 1rem;
}

@media screen and (min-width: 500px) {
    .grid_container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .hero_item {
        grid-column: span 3 / auto;
        grid-row: span 30 / auto;
    }

    .item_wide {
        grid-column: span 2 / auto;
    }
}

@media screen and (max-width: 499px) {
    .grid_container {
        grid-template-columns: 1fr;
    }

    .item_wide {
        grid-column: span 1 / auto;
    }

    .hero_item {
        grid-column: 100%;
        min-height: 60vh;
    }

    .item_image {
        min-height: 300px;
    }
}

.title {
    /* font-family: "Lora", system-ui; */
    /* font-style: italic; */
    font-optical-sizing: auto;
    font-weight: 600;    
    line-height: 120%;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.3));
}

.item_container {
    background-color: rgb(245, 245, 245);
    border-radius: 1rem;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3));
}

.hero_item {
    background-image: url("./assets/lokka.png");
    background-size: cover;
    background-position: top;
    text-align: center;
}


.item_tall {
    grid-row: span 2 / auto;
}

.item_taller {
    grid-row: span 2 / auto;
}

.item_image {
    background-size: cover;
    background-position: center;
}

.some_image {
    background-size: cover;
    background-position: center;
}

.text_item {
    padding: 1rem;
}

.text_bold {
    font-weight: 600;
}

.oslo1_img {
    background-image: url("./assets/oslo1.png");
    height: 10rem;
}

.oslo2_img {
    background-image: url("./assets/oslo2.png");
}

.oslo3_img {
    background-image: url("./assets/oslo3.png");
}

.kristian_tv_img {
    background-image: url("./assets/kristian_tv.png");
}

.munch_img {
    background-image: url("./assets/munch.png");
}

.operaen_img {
    background-image: url("./assets/operaen.png");
}

.torshov_img {
    background-image: url("./assets/torshov.png");
}

.some_links {
    display: block;
    width: 100%;
    height: 32px;
    border: 1px dotted red;
    overflow: scroll;
}

.instagram_logo {
    background-image: url("./assets/instagram.svg");
    height: 20px;
    width: 20px;
    float: right;
    margin-left: 15px;
}

.linkedin_logo {
    background-image: url("./assets/linked.svg");
    height: 20px;
    width: 20px;
    float: right;
    margin-left: 10px;
}

.facebook_logo {
    background-image: url("./assets/facebook.svg");
    height: 20px;
    width: 20px;
    float: right;
}

.copyright {
    text-align: center;
    margin: 2rem 0 2rem 0;
    color: #504f4f;
}